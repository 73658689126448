import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isAuthenticated: false,
    devicetype: 2,
    token: undefined,
    userId: undefined,
    deptId: undefined,
    productId: undefined,
    phone: undefined,
    idCard: undefined,
    payDataInfo: {
      deptId: undefined,
      productId: undefined,
      userId: undefined,
      userName: undefined,
      phone: undefined,
      idCard: undefined,
      bankCard: undefined,
      bankName: undefined,
      bankAddress: undefined,
      money: undefined,
      type: 0
    },
    okSign: [{
      url: null,
      processId: null,
      docId: null
    }],
    newInfo: {}
  },
  getters: {},
  mutations: {
    setIsAuthenticated(state, status) {
      state.isAuthenticated = status;
    },
    setDeviceType(state, type) {
      state.devicetype = type;
    },
    setToken(state, token) {
      state.token = token
    },
    setUserId(state, userId) {
      state.userId = userId
    },
    setDeptId(state, deptId) {
      state.deptId = deptId
    },
    setProductId(state, productId) {
      state.productId = productId
    },
    setPhone(state, phone) {
      state.phone = phone
    },
    setIdCard(state, idCard) {
      state.idCard = idCard
    },
    setPayDataInfo(state, payDataInfo) {
      state.payDataInfo = payDataInfo
    },
    setOkSign(state, okSign) {
      state.okSign = okSign
    },
    setNewInfo(state, newInfo) {
      state.newInfo = newInfo
    }
  },
  actions: {
    updateIsAuthenticated({commit}, status) {
      commit('setIsAuthenticated', status);
    },
    updateDeviceType({commit}, type) {
      commit('setDeviceType', type);
    },
    updateToken({commit}, token) {
      commit('setToken', token);
    },
    updateUserId({commit}, userId) {
      commit('setUserId', userId);
    },
    updateDeptId({commit}, deptId) {
      commit('setDeptId', deptId);
    },
    updateProductId({commit}, productId) {
      commit('setProductId', productId);
    },
    updatePhone({commit}, phone) {
        commit('setPhone', phone);
    },
    updateIdCard({commit}, idCard) {
        commit('setIdCard', idCard);
    },
    updatePayDataInfo({commit}, payDataInfo) {
      commit('setPayDataInfo', payDataInfo);
    },
    updateOkSign({commit}, okSign) {
       commit('setOkSign', okSign);
    },
    updateNewInfo({commit}, newInfo) {
       commit('setNewInfo', newInfo);
    }
  },
  plugins: [createPersistedState({
    storage: {
      getItem: (key) => {
        const value = window.localStorage.getItem(key);
        if (value) {
          const parsed = JSON.parse(value);
          // 检查数据是否过期
          if (Date.now() > parsed.expireAt) {
            // 如果过期，删除该项并返回null
            window.localStorage.removeItem(key);
            return null
          }
          return parsed.data;
        }
        return null;
      },
      setItem: (key, value) => {
        // 设置一个过期时间，例如30分钟后
        const expireAt = Date.now() + 710 * 60 * 1000;
        // 存储数据时，包含数据和过期时间戳
        window.localStorage.setItem(key, JSON.stringify({ data: value, expireAt }));
      },
      removeItem: window.localStorage.removeItem.bind(window.localStorage)
    }
  })],
  // plugins: [createPersistedState({
  //   storage: window.localStorage, // 或者 window.sessionStorage
  //   reducer: state => ({
  //     // 只持久化你需要的数据
  //     isAuthenticated: state.isAuthenticated,
  //     devicetype: state.devicetype,
  //     token: state.token,
  //     userId: state.userId,
  //     deptId: state.deptId,
  //     productId: state.productId,
  //   })
  // })],
  modules: {}
})
