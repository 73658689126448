<template>
  <div class="app">
    <header class="header">
      <div class="back" @click="handleBack">
        <i class="el-icon-arrow-left" style="left: 0"></i>
      </div>
      <span class="title">新闻资讯</span>
    </header>
    <div class="body">
      <img style="width: 100%" :src="newInfo.imgUrl">
      <div class="card">
        <div class="card-text1">{{newInfo.title}}</div>
        <div class="card-text2">{{newInfo.content}}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'NewIndex',
  data() {
    return {
      newInfo: this.$store.state.newInfo
    }
  },
  created() {
    console.log(this.$store.state.newInfo)
  },
  methods: {
    handleBack() {
      this.$router.go(-1)
    },
  }
}
</script>
<style scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: center; /* 使标题居中显示 */
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  line-height: 40px;
}

.back {
  position: absolute;
  left: 10px;
  cursor: pointer;
  color: black;
  font-size: 25px;
}

.title {
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

.body {
  padding: 20px;
}

.card {
  padding: 10px;
  border-radius: 8px;
  background-color: #fff;
  margin-top: 10px;
  margin-bottom: 10px;
}

.card-text1 {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
  text-align: left;
}

.card-text2 {
  font-size: 16px;
  color: #666;
  line-height: 1.5;
  text-indent: 2em;
  margin-bottom: 10px;
  text-align: left;
}
</style>
