import Vue from 'vue'
import VueRouter from 'vue-router'
//通道页面
import Index from '@/views/display/index.vue'
import NewIndex from '@/views/new/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/news/:data?',
    name: 'NewIndex',
    component: NewIndex
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach((to, from, next) => {
//   // 检查用户是否已经登录
//   const isAuthenticated = store.state.isAuthenticated
//   //判断是否是扫码登录
//   const isScan = store.state.sacnInfo.productId == '' ? false : true
//   if (!isAuthenticated && to.path !== '/login') {
//     // 如果用户未登录并尝试访问非登录页面，则重定向到登录页面
//     next('/login')
//   } else if (isAuthenticated && to.path === '/login') {
//     // 如果用户已登录且访问登录页，重定向到仪表板
//     next('/product')
//   } else {
//     // 其他情况放行
//     next()
//   }
// })

export default router



