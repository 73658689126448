<template>
  <div class="app">
<!--    <img src="@/assets/hmback.png" class="vec-rectangle-vGs-4"/>-->
    <div class="card1">
      <el-row>
        <div class="span-rZo-1">毫米基金</div>
      </el-row>
      <div class="card1-div3">
        <el-row>
          <el-col :span="6">
            <div class="card1-div1">
              <span class="card1-text1">毫米平台</span>
            </div>
          </el-col>
          <el-col :span="18">
            <div class="card1-div2">
              <span class="card1-text1">专业创造价值，真诚服务客户！</span>
            </div>
          </el-col>
        </el-row>
        <div class="card1-text3">
          已为用户累计赚取1亿+
        </div>
        <el-row>
          <el-col :span="12">
            <div class="card1-text4">
              ·累计服务用户10万+
            </div>
          </el-col>
          <el-col :span="12">
            <div class="card1-text4">
              ·100+金融产品
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="body">
      <div class="card2">
        <el-row style="margin-top: 20px;">
          <el-col :span="6">
            <div @click="handleModule" class="drawer-div">
              <img class="card-img" src="@/assets/wykh.png">
              <div class="card2-text1">
                我要开户
              </div>
            </div>
          </el-col>
          <el-col :span="6">
            <div @click="handleModule" class="drawer-div">
              <img class="card-img" src="@/assets/jjph.png">
              <div class="card2-text1">
                基金排行
              </div>
            </div>
          </el-col>
          <el-col :span="6">
            <div @click="handleModule" class="drawer-div">
              <img class="card-img" src="@/assets/dtzq.png">
              <div class="card2-text1">
                定投专区
              </div>
            </div>
          </el-col>
          <el-col :span="6">
            <div @click="handleModule" class="drawer-div">
              <img class="card-img" src="@/assets/wdzc.png">
              <div class="card2-text1">
                我的资产
              </div>
            </div>
          </el-col>
        </el-row>

        <el-row style="margin-top: 20px;">
          <el-col :span="6">
            <div @click="handleModule" class="drawer-div">
              <img class="card-img" src="@/assets/jyjl.png">
              <div class="card2-text1">
                交易记录
              </div>
            </div>
          </el-col>
          <el-col :span="6">
            <div @click="handleModule" class="drawer-div">
              <img class="card-img" src="@/assets/symx.png">
              <div class="card2-text1">
                收益明细
              </div>
            </div>
          </el-col>
          <el-col :span="6">
            <div @click="handleModule" class="drawer-div">
              <img class="card-img" src="@/assets/zczm.png">
              <div class="card2-text1">
                资产证明
              </div>
            </div>
          </el-col>
          <el-col :span="6">
            <div @click="handleModule" class="drawer-div">
              <img class="card-img" src="@/assets/zhsz.png">
              <div class="card2-text1">
                账户设置
              </div>
            </div>
          </el-col>
        </el-row>
      </div>

      <div class="card3">
        <div class="card3-text1">优选基金</div>
        <div class="img-EtP-7"></div>
        <div v-if="dataList.length > 0">
          <div v-for="(item, index) in dataList" :key="index" @click="handleMore(item)">
            <el-row>
              <el-col :span="20">
                <div class="card3-text2">{{item.name}}</div>
              </el-col>
              <el-col :span="4">
                <div class="card3-text3">查看></div>
              </el-col>
            </el-row>
            <div class="card3-text4">{{item.remark}}</div>
            <div class="img-EtP-7"></div>
          </div>
        </div>
        <el-empty description="暂无数据" v-else></el-empty>
      </div>

      <div>
        <div class="card4">
          <div class="card4-text1">新闻资讯</div>
          <div class="img-EtP-7"></div>
          <div v-if="dataList.length > 0">
            <div v-for="(item, index) in newList" :key="index" @click="handleNewClick(item)">
              <el-row>
                <el-col :span="16">
                  <div class="card4-text2">{{item.title}}</div>
                  <div class="card4-text3">{{item.createTime}}</div>
                </el-col>
                <el-col :span="8">
                  <el-image
                    :src="item.imgUrl"
                  ></el-image>
                </el-col>
              </el-row>
              <div class="img-EtP-7"></div>
            </div>
          </div>
          <el-empty description="暂无数据" v-else></el-empty>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { list, newList } from '@/api/jump/index'
export default {
  name: 'BasLoginDisplay',
  data() {
    return {
      dataList: [],
      newList: []
    }
  },
  created() {
    this.getList()
    this.getNewList()
  },
  methods: {
    getList() {
      list().then(res => {
        this.dataList = res.dataInfo
      })
    },
    getNewList() {
      newList().then(res => {
        this.newList = res.dataInfo
      })
    },
    handleModule() {
      this.$message.info('敬请期待')
    },
    handleMore(item) {
      window.open(item.url, '_self');
    },
    handleNewClick(item) {
      this.$store.commit('setNewInfo', item)
      window.location.href = '/news'
    }
  }
}
</script>

<style scoped>
.body {
  padding: 10px;
}

.card1 {
  z-index: 2;
  padding: 10px;
  background: linear-gradient(145.72deg, rgba(247, 219, 186, 1) 0%, rgba(234, 182, 125, 1) 100%);
}

.card1-div1 {
  height: 24px;
  border-radius: 0px 10px 0px 10px;
  mix-blend-mode: normal;
  background: rgba(147, 101, 64, 1);
}

.card1-div2 {
  height: 24px;
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0.18) 1.39%, rgba(255, 214, 174, 0) 100%);
  mix-blend-mode: normal;
}

.span-rZo-1 {
  font-size: 25px;
  font-weight: 800;
  color: rgba(56, 56, 56, 1);
  text-align: center;
  margin-top: 20px;
}

.card1-text1 {
  font-size: 14px;
  font-weight: 500;
  color: rgba(254, 232, 214, 1);
}

.card1-div3 {
  padding: 20px;
  margin-top: 20px;
  border-radius: 12px;
  background: #2A1F10;
}

.card1-text3 {
  margin-top: 10px;
  font-size: 24px;
  font-weight: 700;
  color: rgba(254, 232, 214, 1);
  text-align: left;
}

.card1-text4 {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
  color: rgba(254, 232, 214, 1);
  text-align: left;
}

.card2 {
  border-radius: 12px;
  background: rgba(255, 255, 255, 1);
  padding: 10px;
}

.card2-text1 {
  font-size: 15px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
}

.card3 {
  margin-top: 10px;
  height: auto;
  border-radius: 12px;
  background: rgba(255, 255, 255, 1);
  padding: 10px;
}

.card3-text1 {
  font-size: 18px;
  font-weight: 800;
  color: rgba(0, 0, 0, 1);
  text-align: left;
}

.card3-text2 {
  font-size: 18px;
  font-weight: 800;
  color: rgba(56, 56, 56, 1);
  text-align: left;
}

.card3-text3 {
  font-size: 16px;
  font-weight: 500;
  color: rgba(166, 166, 166, 1);
  text-align: right;
}

.card3-text4 {
  font-size: 13px;
  font-weight: 500;
  color: rgba(186, 192, 203, 1);
  text-align: left;
}

.card4 {
  margin-top: 10px;
  height: auto;
  border-radius: 12px;
  background: rgba(255, 255, 255, 1);
  padding: 10px;
}

.card4-text1 {
  font-size: 18px;
  font-weight: 800;
  color: rgba(0, 0, 0, 1);
  text-align: left;
}

.card4-text2 {
  font-size: 14px;
  font-weight: 600;
  color: rgba(56, 56, 56, 1);
  text-align: left;
}

.card4-text3 {
  font-size: 14px;
  font-weight: 400;
  color: rgba(128, 128, 128, 1);
  text-align: left;
  margin-top: 10px;

}

.img-EtP-7 {
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid rgba(229, 229, 229, 0.4);
}

.card-img {
  width: 30px;
}

.drawer-div {
  transition: background-color 0.3s; /* 变色过渡效果 */
}

.drawer-div:active {
  background-color: rgb(207, 206, 206); /* 鼠标点击时的背景色 */
}
</style>
