import request from "@/utils/request";

// 获取基金跳转列表
export async function list() {
  return await request({
    url: '/api/news-finance/finance/list',
    method: 'get'
  })
}

// 获取基金新闻列表
export async function newList() {
  return await request({
    url: '/api/news-finance/finance/new/list',
    method: 'get'
  })
}
